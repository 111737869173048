/**
 * @license
 * Copyright Abservetech. All Rights Reserved.
 */
export class AppSettings {
  public static production = false;
  public static API_ENDPOINT = "https://api.towner.taxi/adminapi/";
  public static HOT_ENDPOINT = "https://api.towner.taxi/company/";
  public static VEH_ENDPOINT = "https://api.towner.taxi/api/";
  public static FILEURL = "https://api.towner.taxi/public/";
  public static BASEURL = "https://api.towner.taxi/";
  public static FEURL = "https://api.towner.taxi/company/";
  public static BLOBURL = "https://stgenttowner.blob.core.windows.net/enttownercontainer/";
  public static DLURL = "https://towner.taxi/tripInvoice/";
  public static APPNAME = "Towner";
  public static APPYEAR = new Date().getFullYear();
  public static defaultPhoneCode = "+91";
  public static defaultlang = "EN";
  public static defaultcur = "₹";
  public static defaultCountryId = "101"; // India
  public static defaultStateId = "35"; // TN
  public static GOOGLE_MAP_DEFAULT_LOCATION = "Banglore";
  // It is used for showing default location of google map to show the location details*/
  public static GoogleMapKey = "AIzaSyCbp_XuIxJX87GCbQGBFD8ryIhH2WLEcn8";
  public static logo_URL = "assets/images/logo.png";
  public static version = "2.0.1";
  public static showLogo = true;
  public static showTitle = false;
  public static logo_height = 67;
  public static logo_width = 198;
  public static secondCurrency = "INR";
  public static toShowSeocndCurrency = false;
  public static DeleteDriver = true;
  public static MAP_ZOOM = 10;
}

export class featuresSettings {
  public static isDTS = false;
  public static isDownTown = false;
  public static isCityWise = false; // Vehicle and Fare etc act citywise  0/1
  public static isMultipleTaxiNotAllowedForDrivers = false;
  public static isHotel = false; // Vehicle and Fare etc act citywise  0/1
  public static isServiceAvailable = true; // if not modify in getServiceBasicfare
  public static isCommisionToAdmin = true; // is at end of trip Commision need to take
  public static isMultipleCompaniesAvailable = true;
  public static isVehiclePoolAvailable = false;
  public static isDoubleChargeNeeded = false; // is isDoubleChargeNeeded for crossing City limit
  public static applyNightCharge = true;
  public static applyPeakCharge = true;
  public static HotelBooking = false;
  public static applyWaitingTime = true; // waiting time charge
  public static applyTax = true;
  public static applyCommission = true;
  public static applyPickupCharge = true;
  public static isRiderCancellationAmtApplicable = true;
  public static isDriverCancellationAmtApplicable = false;
  public static applyBlockOldCancellationAmt = true;
  public static isDriverCreditModuleEnabled = true;
  // Helps to show alert if credits are low, reduce commision from credits, etc
  public static adminCommision = "driverWallet"; // From Driver Wallet (driverWallet)
  public static payoutType = "driverPostpaid";
  // Driver Have to Recharge to Take Trip. (driverPrepaidWallet,driverPostpaidWallet)
  public static deductAmountFromDriverWallet = "commision";
  // this amount will be decuted from Wallet (totalFare,commision)
  public static isRiderReferalCodeAvailable = false;
  public static isPromoCodeAvailable = true;
  public static isOffersForRideAvailable = false;
  public static tripsAvailable = ["Daily"]; // USED
  public static fareCalculationType = "normal"; // {indiaGst,normal}
  public static distanceUnit = "KM"; // KM or Miles
  public static showHailTrips = false; // show hail related stuffs
  public static payPackageTypes = ["subscription", "topup"]; // 'subscription,commission,topup'
  public static shareTaxi = false; // Is Vehicle Sharing Available in Vehicle Type
  public static taxFeeLabel = " GST Tax"; // Access Fee or Tax
  public static showDeliveryTrips = false; // Delivery Trips and Report
  public static defaultVehicleInMap = ""; // Gods View and Tracking
  public static referenceCode = false;
  public static langAvailable = ["en", "es"];
  public static isVehicleTypeAvailableInPackages = false; // Vehicle Type while adding package in Driver Credits.
  public static disableVehicleTypeName = true; // disable vehicle type in edit
  public static NepalStates = 153;
  public static perpage = 10;
  public static paymentgateway = false;
  public static currencyconversion = false;
  public static DefaultCountry = "101";
  public static DefaultState = "41";
}

export class FirebaseConfig {
  public static firebaseConfig = {
    apiKey: "AIzaSyCvr9noGfMRsMf9jHgM4QWiU9sgoaYscFM",
    authDomain: "papa-taxi-823d9.firebaseapp.com",
    databaseURL: "https://papa-taxi-823d9.firebaseio.com",
    projectId: "papa-taxi-823d9",
    storageBucket: "papa-taxi-823d9.appspot.com",
    messagingSenderId: "414505975837",
  };
}

export class AdminMenuConfig {
  public static hideFieldsForProviderLogin = false;
  public static showNormalMenu = [
    { label: "Super Admin", value: "superadmin" },
    { label: "Dispatcher Admin", value: "dispatcher" },
    { label: "Billing Admin", value: "billing" },
    { label: "Provider Admin", value: "provider" },
  ];
  public static showCityWiseMenu = [
    { label: "Super Admin", value: "superadmin" },
    { label: "City Wise Admin", value: "citywiseadmin" },
    { label: "Dispatcher Admin", value: "dispatcher" },
    { label: "Billing Admin", value: "billing" },
    { label: "Provider Admin", value: "provider" },
  ];
}

export class FarefieldConfig {
  public static showFareFromConfig = false; // MTD Fare
  public static showManuallyAddPickupCharge = false;
}

export class inputValidation {
  public static fnameValid = {
    pattern: "[a-zA-Z ]*",
    minlength: 3,
    maxlength: 25,
  };
  public static lnameValid = {
    pattern: "[a-zA-Z ]*",
    minlength: 3,
    maxlength: 25,
  };
  public static emailValid =
    "[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}";
  public static pwdValid = {
    minlength: 8,
    maxlength: 15,
  };
  // public static pwdValid = "^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#_$%(+=)^&*])[a-zA-Z0-9!@#_(+=)$%^&*]{6,8}$";
  public static phoneValid = {
    pattern: "^[0-9]*$",
    minlength: "10",
    maxlength: "10",
  };
  public static accountNumberValid = {
    pattern: "^[0-9]*$",
    minlength: "10",
    maxlength: "18",
  };
}

export class dropdown {
  public static dropdownSettings = {
    singleSelection: false,
    idField: "scId",
    textField: "name",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };
}

export class documentSettings {
  public static showOptionalFieldDriver = false;
  public static driverTaxiLabels = ["PA/CPC Number"];
  public static setDriverTaxiLabel = false;
  public static driverDocsWithNumberAndImage = [
    // {
    //   label: 'Aadhar  ',
    //   value: 'aadhaar',
    //   nolabel: 'Aadhar No',
    //   novalue: 'aadhaarNo',
    // },
    //  {
    //    label: "Licnence",
    //    value: "licence",
    //    nolabel: "Licnence No",
    //    valuelabel: "licenceNo",
    //  },
  ];
  public static driverDocsWithNumberOnly = [
    // {
    //   label: 'Badge Number',
    //   value: 'badge',
    // },
  ];
  public static driverDocsWithExpiryNumberImage = [
    // {
    //   label: "Licence",
    //   value: "licence",
    //   nolabel: "Badge Licence No",
    //   novalue: "licenceNo",
    //   explabel: "Licence Expiry Date",
    //   expvalue: "licenceexp",
    // },
    // {
    //   label: "Authorisation Letter",
    //   value: "badge",
    //   nolabel: "Badge Licence No",
    //   novalue: "licenceNo",
    //   explabel: "Licence Expiry Date",
    //   expvalue: "licenceexp",
    // },
  ];
  public static driverDocsWithMultipleImages = [
    { label: "Driving Licence", value: ["licence", "licenceBackImg"] },
    // {label: "Authorisation Letter ", value: ["licence", "licenceBackImg"]},
  ];
  public static driverDocsWithMultipleImgWithoutDate = [
    // {label: "Address Proof", value: ["passing", "passingBackImg"]},
    // {
    //   label: 'Owner Address Proof',
    //   value: ['insurance', 'insuranceBackImg'],
    // },
  ];
  public static driverDocsWithoutExpiryDate = [
    {
      label: "Authorisation  Letter",
      value: "panCard",
    },
  ];
  public static driverDocs = [];
  public static taxiDocsWithMultipleImages = [];
  public static taxiDocs = [
    { label: "Taxi Permit", value: "permit" },
    { label: "Taxi Insurance", value: "insurance" },
  ];
  public static taxiDocsWithoutExpiryDate = [];
  public static taxiDocsWithManualFields = [
    {
      label: "Taxi Registration Certificate (RC)",
      value: ["registration", "registrationexpdate"],
    },
    {
      label: "Fitness Certificate",
      value: ["fc", "fcexpdate"],
    },
    { label: "PUC Certificate", value: ["puc", "pucexpdate"] },
  ]; // first value for img and second for expdate
}

// abservetech
